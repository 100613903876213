/* eslint-disable @typescript-eslint/no-explicit-any */
import operationSignatures from '../_constants/operationSignatures.json';
import { MOCK_SESSION_ID, getSessionData, prePageCall } from '@marriott/mi-headless-utils';
import { GetServerSideProps } from 'next';
//This returns a component which can be rendered in any product specific [[page.tsx]]
//to return the page template(Head, main, sections) along with the required react Components mentioned in model.json and
// load the CSS and JS libs required by static components from AEM.
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { ApolloProvider } from '@apollo/client';
import { getDeployedEnvType, useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { Layout, respGridUtil } from '@marriott/mi-headless-utils';
import clsx from 'clsx';
import { HotelDirectoryModel } from '@marriott/mi-shop-components/utils';
import { inspect } from 'util';

const {
  SHOP_UXL_CALL_SPLIT,
  SESSION_APP_GET_CALL_URL,
  SESSION_APP_POST_CALL_URL,
  MI_DATALAYER_APP_URL,
  NEXT_PUBLIC_DATALAYER_SITENAME,
  NEXT_PUBLIC_DATALAYER_PRODUCT,
  NEXT_PUBLIC_DATALAYER_APPLICATION,
  NEXT_PUBLIC_DATALAYER_TEMPLATE,
  NEXT_PUBLIC_DATALAYER_VARIATION,
  NEXT_PUBLIC_ORIENTATION_LANDSCAPE,
  NEXT_PUBLIC_ORIENTATION_PORTRAIT,
  NEXT_PUBLIC_PATHTYPE_NEW,
  NEXT_PUBLIC_PATHTYPE_LEGACY,
  NEXT_PUBLIC_SEARCH_SINGLE_RATE,
  NEXT_PUBLIC_RAM_TILE_NAME,
  NEXT_PUBLIC_PAGE_BREAKPT,
  NEXT_PUBLIC_SEARCH_MAPVIEW,
  NEXT_PUBLIC_SEARCH_LISTVIEW,
  IS_LOCAL_DEV,
} = process.env;

export default function HotelDirectoryPage({ model, pagePath, operationSignatures, isAuthorMode }) {
  const MiApolloClient = useNextMiApolloClient(operationSignatures, getDeployedEnvType());

  const {
    metaNames,
    metaProperties,
    title,
    staticComponentClientLibsCSS,
    staticComponentClientLibsJS,
    gridCss,
    hreflangList,
    canonical,
  } = model;
  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);

  return (
    <ApolloProvider client={MiApolloClient}>
      <Layout
        metaNames={metaNames}
        metaProps={metaProperties}
        title={title}
        staticComponentClientLibsCSS={staticComponentClientLibsCSS}
        staticComponentClientLibsJS={staticComponentClientLibsJS}
        isAuthorMode={isAuthorMode}
        gridCss={gridCss || ''}
        hreflangList={hreflangList}
        canonical={canonical}
      >
        <section>
          <div className={clsx('mx-auto', 'max-w-7xl', 'sm:px-6', 'lg:px-8', 'sm:py-2', 'lg:py-6')}>
            <ResponsiveGrid
              key={pagePath}
              {...respGridModel}
              model={respGridModel}
              pagePath={pagePath}
              itemPath="root/responsivegrid"
            />
          </div>
        </section>
      </Layout>
    </ApolloProvider>
  );
}

const shopSpecificData = async ({ req, resolvedUrl }) => {
  const { log } = global.loggerInstance('HotelDirectory:');
  const resolvedUrlString: string = resolvedUrl ?? req.url;
  log.debug(inspect(`[HD-LOGS] SESSION resolvedUrl IS:${inspect(resolvedUrlString)}`));

  const isAuthorMode = resolvedUrlString.includes('isAuthorMode');
  log.debug(inspect(`[HD-LOGS] SESSION isAuthorMode IS:${inspect(isAuthorMode)}`));

  const sessionId = req?.cookies?.sessionID ?? MOCK_SESSION_ID;
  log.debug(inspect(`[HD-LOGS] SESSION ID :${inspect(sessionId)}`));

  const sessionDoc: any = await getSessionData(sessionId, IS_LOCAL_DEV === 'true', isAuthorMode);
  log.debug(inspect(`[HD-LOGS] SESSION DOC :${inspect(sessionDoc)}`));

  return {
    sessionFetchedData: sessionDoc.sessionData,
    SHOP_UXL_CALL_SPLIT,
    operationSignatures,
    SESSION_APP_GET_CALL_URL,
    SESSION_APP_POST_CALL_URL,
    datalayerParams: {
      MI_DATALAYER_APP_URL,
      NEXT_PUBLIC_DATALAYER_SITENAME,
      NEXT_PUBLIC_DATALAYER_PRODUCT,
      NEXT_PUBLIC_DATALAYER_APPLICATION,
      NEXT_PUBLIC_DATALAYER_TEMPLATE,
      NEXT_PUBLIC_DATALAYER_VARIATION,
    },
    datalayerProperties: {
      NEXT_PUBLIC_ORIENTATION_LANDSCAPE,
      NEXT_PUBLIC_ORIENTATION_PORTRAIT,
      NEXT_PUBLIC_PATHTYPE_NEW,
      NEXT_PUBLIC_PATHTYPE_LEGACY,
      NEXT_PUBLIC_SEARCH_SINGLE_RATE,
      NEXT_PUBLIC_RAM_TILE_NAME,
      NEXT_PUBLIC_PAGE_BREAKPT,
      NEXT_PUBLIC_SEARCH_MAPVIEW,
      NEXT_PUBLIC_SEARCH_LISTVIEW,
    },
  };
};

//SSR Render
export const getServerSideProps: GetServerSideProps<any> = prePageCall(shopSpecificData, HotelDirectoryModel);
